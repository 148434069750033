var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"regular-expression"},[(!_vm.currentView)?_c('div',[_c('filter-levels',{attrs:{"filter-type":"regular_expression","extra-menu-items":[_vm.ExtraSettingsMenuItems.CommonRules, _vm.ExtraSettingsMenuItems.VirtualNew]},on:{"changeCurrentView":_vm.handleCurrentView}}),_c('div',{staticClass:"main-filter-settings mt-5"},[_c('a-card',{attrs:{"title":_vm.$t('field_special_settings_title')}},[_c('chips-input',{attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.$store.state.chatState.configCopy,
              'key': 'regular_expressions',
              'hasAnchor': true
            }
          }}}),_c('strict-mode',{attrs:{"filter-type":"regular_expression","store-path":"$store.state.chatState.configCopy"}}),_c('ignore-caption',{attrs:{"filter-type":"regular_expression","store-path":"$store.state.chatState.configCopy"}})],1),_c('a-card',{staticClass:"mt-5",attrs:{"title":_vm.$t('field_common_settings_title')}},[_c('common-settings',{attrs:{"filter-type":"regular_expression","store-path":"$store.state.chatState.configCopy"}})],1)],1)],1):_c('div',[(_vm.currentView === _vm.ExtraSettingsMenuItems.VirtualNew)?_c('div',[_c('section-wrapper',{attrs:{"section-title":"virtual_new"},on:{"onNavBack":_vm.handleNavBack},scopedSlots:_vm._u([{key:"fields",fn:function(){return [_c('virtual-new-settings',{attrs:{"filter-type":"regular_expression","store-path":"$store.state.chatState.configCopy"}})]},proxy:true}],null,false,3208570466)})],1):_vm._e(),(_vm.currentView === _vm.ExtraSettingsMenuItems.CommonRules)?_c('div',[_c('section-wrapper',{attrs:{"section-title":"common_rules"},on:{"onNavBack":_vm.handleNavBack},scopedSlots:_vm._u([{key:"fields",fn:function(){return [_c('common-rules-settings',{attrs:{"filter-type":"regular_expression","store-path":"$store.state.chatState.configCopy"}})]},proxy:true}],null,false,195961551)})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }